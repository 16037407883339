<template>
  <v-container fluid>
    <div class="home row d-flex justify-md-space-between pt-8" id="section1">
      <div class="col-12 col-md-4 pt-0 pt-md-16 d-flex justify-center">
        <div class="pl-md-16">
          <h1 class="text-center text-md-start mr-md-8">
            {{ lang.header.title }} <br/>
            {{ lang.section1.title }}
          </h1>
          <p class="mt-7 text-center text-md-start mr-md-8">
            {{ lang.section1.subtitle }}
          </p>
          <div class="d-flex justify-center">
            <div
                class="d-flex mt-5 mt-md-10 px-3"
                @click="$vuetify.goTo('#section4')"
            >
              <div
                  style="background-color: #45b08c"
                  class="download_btn d-flex"
              >
                <div
                    style="
                    background-color: #45b08c;
                    min-width: 130px;
                    border-radius: 4px;
                  "
                    class="py-3 px-1 text-center white--text"
                >
                  {{ lang.section1.download_btn_title }}
                </div>
                <div
                    style="
                    background-color: #289b73;
                    min-width: 50px;
                    border-radius: 2px;
                  "
                    class="py-3 px-1 text-center"
                >
                  <span class="material-icons-round"
                        style="color: white; min-width: 35px;margin-top: 5px">download</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cheetah col-12 col-md-8 mt-8 mt-md-0">
        <div class="row d-flex justify-end justify-sm-end">
          <div class="col-4 col-sm-4 mt-5">
            <v-img src="@/assets/Images/phone.png" alt="Phone"></v-img>
          </div>
          <div class="col-7 col-sm-7  px-0">
            <v-img
                v-if="this.lang.header.selectLang.country == 'Iran'"
                src="@/assets/Images/mockup_fa.png" alt="Laptop"></v-img>
            <v-img
                v-else
                src="@/assets/Images/Laptop.png"
                alt="Laptop"></v-img>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ row: true, Gradient: !this.DarkTheme }" id="section2">
      <div class="col-12 col-md-4 pt-2 pt-md-16 d-flex justify-center">
        <div class="pl-md-16 margin_top">
          <h1 class="text-center text-md-start mr-md-8">
            {{ lang.section2.title }}
          </h1>
          <p class="mt-7 text-center text-md-start mr-md-8">
            {{ lang.section2.subtitle }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <v-tabs
            id="notix"
            class="mt-md-16 mx-auto"
            v-model="tab"
            background-color="transparent"
            centered
            dark
            icons-and-text
        >
          <v-tab href="#tab-5" @click="changeStyle('cast')">
            <div class="px-1 px-md-2">
              <v-img v-show="cast" src="../assets/Images/cast2.png"></v-img>
              <v-img
                  v-show="!cast"
                  src="../assets/Images/cast_unsekected.png"
              ></v-img>
            </div>
          </v-tab>

          <v-tab href="#tab-4" @click="changeStyle('call_log')">
            <div class="px-2 px-md-2">
              <v-img
                  v-show="call_log"
                  src="../assets/Images/call_log_selected2.png"
              ></v-img>
              <v-img
                  v-show="!call_log"
                  src="../assets/Images/call_log_unselected2.png"
              ></v-img>
            </div>
          </v-tab>

          <v-tab href="#tab-3" @click="changeStyle('chat')">
            <div class="px-2 px-md-2">
              <v-img
                  v-show="chat"
                  src="../assets/Images/chat_selected.png"
                  style=""
              ></v-img>
              <v-img
                  v-show="!chat"
                  src="../assets/Images/chat_unselected2.png"
              ></v-img>
            </div>
          </v-tab>

          <v-tab href="#tab-2" @click="changeStyle('notif')">
            <div class="px- px-md-2">
              <v-img
                  v-show="notif"
                  src="../assets/Images/notif_selected2.png"
                  style=""
              ></v-img>
              <v-img
                  v-show="!notif"
                  src="../assets/Images/notif_unselected.png"
              ></v-img>
            </div>
          </v-tab>

          <v-tab href="#tab-1" @click="changeStyle('send')">
            <div class="px-0 px-md- mx-0">
              <v-img
                  v-show="send == true ? true : false"
                  src="../assets/Images/send_selected2.png"
              ></v-img>
              <v-img
                  v-show="send == false ? true : false"
                  src="../assets/Images/send_unselected2.png"
              ></v-img>
            </div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparent">
          <v-tab-item
              :value="'tab-' + 1"
              class="transparent"
              style="height: 100%"
          >
            <v-img src="../assets/Images/send.png"></v-img>
          </v-tab-item>

          <v-tab-item
              :value="'tab-' + 2"
              class="transparent"
              style="height: 100%"
          >
            <v-img src="../assets/Images/notif.png"></v-img>
          </v-tab-item>

          <v-tab-item
              :value="'tab-' + 3"
              class="transparent"
              style="height: 100%"
          >
            <v-img src="../assets/Images/smss.png"></v-img>
          </v-tab-item>

          <v-tab-item
              :value="'tab-' + 4"
              class="transparent"
              style="height: 100%"
          >
            <v-img
                src="../assets/Images/phone_laptop.png"

            ></v-img>
          </v-tab-item>

          <v-tab-item
              :value="'tab-' + 5"
              class="transparent"
              style="height: 100%"
          >
            <v-img src="../assets/Images/cast.png"></v-img>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <div class="py-4 mt-5" id="section3">
      <div class="pl-md-16 mt-5 mb-10">
        <h1 class="text-center text-md-start mr-md-8">
          {{ lang.section3.title }}
        </h1>
      </div>

      <v-slide-group
          class="d-md-none"
          v-model="model"
          center-active
          show-arrows
      >
        <v-slide-item v-slot="{ active, toggle }"></v-slide-item>
        <v-card
            class="mx-3 my-8 text-center box_shadow py-4"
            max-width="250"
            data-aos="fade-up"
        >
          <v-img
              src="../assets/Images/Encryption.png"
              height="100px"
              width="100"
              class="mx-auto"
          ></v-img>

          <v-card-title class="text-center">
            {{ lang.section3.Encryption }}
          </v-card-title>

          <v-card-subtitle>{{
              lang.section3.Encryption_subtitle
            }}
          </v-card-subtitle>
        </v-card>

        <v-card
            class="mx-3 my-8 text-center py-4"
            max-width="250"
            data-aos="fade-down"
        >
          <v-img
              src="../assets/Images/privacy.png"
              height="100px"
              width="100"
              class="mx-auto"
          ></v-img>

          <v-card-title class="text-center">
            {{ lang.section3.Privacy }}
          </v-card-title>

          <v-card-subtitle>
            {{ lang.section3.Privacy_subtitle }}
          </v-card-subtitle>
        </v-card>

        <v-card
            class="mx-3 my-8 text-center py-4"
            max-width="250"
            data-aos="fade-up"
        >
          <v-img
              src="../assets/Images/Resume transfer.png"
              height="100px"
              width="100"
              class="mx-auto"
          ></v-img>

          <v-card-title class="text-center">
            {{ lang.section3.Resume_transfer }}
          </v-card-title>

          <v-card-subtitle>
            {{ lang.section3.Resume_transfer_subtitle }}
          </v-card-subtitle>
        </v-card>

        <v-card
            class="mx-3 my-8 text-center py-4"
            max-width="250"
            data-aos="fade-down"
        >
          <v-img
              src="../assets/Images/Synced music.png"
              height="100px"
              width="100"
              class="mx-auto"
          ></v-img>

          <v-card-title class="text-center">
            {{ lang.section3.Synced_music }}
          </v-card-title>

          <v-card-subtitle>
            {{ lang.section3.Synced_music_subtitle }}
          </v-card-subtitle>
        </v-card>

        <v-card
            my-2
            class="mx-3 my-8 text-center py-4"
            max-width="250"
            data-aos="fade-up"
        >
          <v-img
              src="../assets/Images/Group share.png"
              height="100px"
              width="100"
              class="mx-auto"
          ></v-img>

          <v-card-title class="text-center">
            {{ lang.section3.Group_share }}
          </v-card-title>

          <v-card-subtitle>
            {{ lang.section3.Group_share_subtitle }}
          </v-card-subtitle>
        </v-card>
      </v-slide-group>

      <div class="row d-none d-md-flex justify-center mt-16">
        <div class="col-6 col-md-2 d-flex margin_top_card">
          <v-card
              class="mx-auto text-center box_shadow py-4"
              max-width="250"
              data-aos="fade-up"
          >
            <v-img
                src="../assets/Images/Encryption.png"
                height="100px"
                width="100"
                class="mx-auto"
            ></v-img>

            <v-card-title class="text-center">
              {{ lang.section3.Encryption }}
            </v-card-title>

            <v-card-subtitle>
              {{ lang.section3.Encryption_subtitle }}
            </v-card-subtitle>
          </v-card>
        </div>

        <div class="col-6 col-md-2 d-flex margin_bottom_card">
          <v-card
              class="mx-auto text-center py-4"
              max-width="250"
              data-aos="fade-down"
          >
            <v-img
                src="../assets/Images/privacy.png"
                height="100px"
                width="100"
                class="mx-auto"
            ></v-img>

            <v-card-title class="text-center">
              {{ lang.section3.Privacy }}
            </v-card-title>

            <v-card-subtitle>
              {{ lang.section3.Privacy_subtitle }}
            </v-card-subtitle>
          </v-card>
        </div>

        <div class="col-6 col-md-2 d-flex margin_top_card">
          <v-card
              class="mx-auto text-center py-4"
              max-width="250"
              data-aos="fade-up"
          >
            <v-img
                src="../assets/Images/Resume transfer.png"
                height="100px"
                width="100"
                class="mx-auto"
            ></v-img>

            <v-card-title class="text-center">
              {{ lang.section3.Resume_transfer }}
            </v-card-title>

            <v-card-subtitle>
              {{ lang.section3.Resume_transfer_subtitle }}
            </v-card-subtitle>
          </v-card>
        </div>

        <div class="col-6 col-md-2 d-flex margin_bottom_card">
          <v-card
              class="mx-auto text-center py-4"
              max-width="250"
              data-aos="fade-down"
          >
            <v-img
                src="../assets/Images/Synced music.png"
                height="100px"
                width="100"
                class="mx-auto"
            ></v-img>

            <v-card-title class="text-center">
              {{ lang.section3.Synced_music }}
            </v-card-title>

            <v-card-subtitle>
              {{ lang.section3.Synced_music_subtitle }}
            </v-card-subtitle>
          </v-card>
        </div>

        <div class="col-6 col-md-2 d-flex margin_top_card">
          <v-card
              class="mx-auto text-center py-4"
              max-width="250"
              data-aos="fade-up"
          >
            <v-img
                src="../assets/Images/Group share.png"
                height="100px"
                width="100"
                class="mx-auto"
            ></v-img>

            <v-card-title class="text-center">
              {{ lang.section3.Group_share }}
            </v-card-title>

            <v-card-subtitle>
              {{ lang.section3.Group_share_subtitle }}
            </v-card-subtitle>
          </v-card>
        </div>
      </div>
    </div>

    <div class="pt-2" ref="section4" id="section4">
      <div
          :class="{
          row: true,
          'justify-md-space-between': true,
          Gradient: !this.DarkTheme,
          'mt-md-15': true,
        }"
      >
        <div class="col-12 col-md-8">
          <div>
            <div class="pl-md-16 mb-5">
              <h1 class="text-center text-md-start mr-md-8">
                {{ lang.section4.title }}
              </h1>
              <p class="text-center text-md-start mt-5 mr-md-8">
                {{ lang.section4.subtitle }}
              </p>
            </div>

            <div class="row d-flex justify-center my-16">
              <div class="col-12 col-sm-4 col-md-5 d-flex justify-center">
                <div>
                  <div style="height: 100px" class="pt-md-7">
                    <v-img
                        src="../assets/Images/android_selected.png"
                        class="download_logo center"
                    ></v-img>
                    <h3 class="text-center mt-md-6">
                      {{ lang.section4.android_title }}
                    </h3>
                  </div>

                  <div class="text-center">
                    <div class="ma-2 mt-5 mt-md-13">
                      <v-btn
                          v-if="this.lang.header.selectLang.country == 'Iran'"
                          href="https://cafebazaar.ir/app/?id=world.arteh.cheetah&ref=share"
                          class="bazar_fr_bgImg mb-0 mb-md-1 mb-0 mb-md-2 hidden"
                          to=""
                          large
                          width="155px"
                          hidden
                      >
                      </v-btn>
                      <v-btn
                          class="
                          mb-5 mb-md-3
                          ma-3
                          mt-5 mt-sm-5 mt-md-1
                          googleplay_fr_bgImg
                        "
                          large
                          width="155px"
                          href="https://play.google.com/store/apps/details?id=world.arteh.cheetah"
                      >
                      </v-btn>
                    </div>

                    <div class="ma-2 mt-2 mt-sm-2 mt-md-3">

                      <v-btn
                          v-if="this.lang.header.selectLang.country == 'Iran'"
                          class="myket_fr_bgImg mb-0 mb-md-1 mb-0 mb-md-2"
                          href="https://myket.ir/app/world.arteh.cheetah"
                          large
                          width="155px"
                          value=""
                      >
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-4 col-md-3 d-flex justify-center">
                <div>
                  <div style="height: 100px">
                    <v-img
                        src="../assets/Images/linux_selected.png"
                        class="download_logo center"
                    ></v-img>
                    <h3 class="text-center mt-3">
                      {{ lang.section4.linux_title }}
                    </h3>
                  </div>

                  <div class="text-center mt-7 mt-md-0 mx-4">
                    <div class="d-flex px-3">
                      <a
                          href="https://www.arteh.app/cheetah/download/cheetah-lin.zip"
                          style="background-color: #45b08c"
                          class="download_btn d-flex mt-5 mt-md-14"
                      >
                        <div
                            style="background-color: #45b08c; border-radius: 4px; min-width: 150px"
                            class="py-2 px-2 text-center white--text"
                        >
                          {{ lang.section4.linux_btn_title }}
                          <span style="font-size: 12px">{{
                              lang.section4.linux_sh_btn_title
                            }}</span>
                        </div>
                        <div
                            style="background-color: #289b73; border-radius: 2px"
                            class="py-2 px-1 text-center"
                        >
                          <span class="material-icons-round" style="color: white; min-width: 35px;margin-top: 5px">download</span>
                        </div>
                      </a>
                    </div>

                    <div class="d-flex px-3">
                      <a
                          href="https://www.arteh.app/cheetah/java/java-lin.zip"
                          style="background-color: #516091"
                          class="download_btn d-flex mt-8 mt-md-8"
                      >
                        <div
                            style="background-color: #516091; border-radius: 4px; min-width: 150px"
                            class="py-2 px-2 text-center white--text"
                        >
                          {{ lang.section4.linux_btn_title }}
                          <span style="font-size: 12px">{{
                              lang.section4.linux_java_btn_title
                            }}</span>
                        </div>
                        <div
                            style="background-color: #3a4b7c; border-radius: 2px"
                            class="py-2 px-1 text-center"
                        >
                          <span class="material-icons-round" style="color: white; min-width: 35px;margin-top: 5px">download</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-4 col-md-3 d-flex justify-center">
                <div>
                  <div style="height: 100px">
                    <v-img
                        src="../assets/Images/win_selected.png"
                        class="download_logo center"
                    ></v-img>
                    <h3 class="text-center mt-5">
                      {{ lang.section4.Windows_title }}
                    </h3>
                  </div>

                  <div class="text-center mt-7 mt-md-0">
                    <div class="d-flex px-3">
                      <a href="https://www.arteh.app/cheetah/download/cheetah-win.rar"
                         style="background-color: #45b08c"
                         class="download_btn d-flex mt-5 mt-md-14">
                        <div
                            style="background-color: #45b08c; border-radius: 4px; min-width: 150px"
                            class="py-2 px-2 text-center white--text">
                          {{
                            lang.section4.Windows_btn_title
                          }}<span style="font-size: 12px">{{
                            lang.section4.Windows_exe_btn_title
                          }}</span>
                        </div>
                        <div
                            style="background-color: #289b73; border-radius: 2px"
                            class="py-2 px-1 text-center"
                        >
                          <span class="material-icons-round" style="color: white; min-width: 35px;margin-top: 5px">download</span>
                        </div>
                      </a>
                    </div>

                    <div class="d-flex px-3">
                      <a
                          href="https://www.arteh.app/cheetah/java/java-win.rar"
                          style="background-color: #516091"
                          class="download_btn d-flex mt-8 mt-md-8"
                      >
                        <div
                            style="background-color: #516091; border-radius: 4px; min-width: 150px"
                            class="py-2 px-2 text-center white--text"
                        >
                          {{ lang.section4.Windows_btn_title }}
                          <span style="font-size: 12px;">{{
                              lang.section4.Windows_java_btn_title
                            }}</span>
                        </div>
                        <div
                            class="py-2 px-1 "
                            style="background-color: #3a4b7c; border-radius: 2px;"
                        >
                          <span class="material-icons-round" style="color: white; min-width: 35px;margin-top: 5px">download</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 pr-0 pt-md-16">
          <v-img
              class="mt-0 mt-md-16"
              src="../assets/Images/download.png"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- <div class="px-md-10 my-15 pt-10" id="section5">
  <div>
        <h1 class="text-center text-md-start mr-md-8 pl-md-9">
          {{ lang.section5.title }}
        </h1>
      </div> -->

    <!-- <v-slide-group v-model="model" class="mt-8" show-arrows>
      <v-slide-item
        v-for="(comment, i) in lang.section5.comments"
        :key="i"
        v-slot="{ active, toggle }"
      >
        <v-card class="elevation-1 mx-4 my-8" width="250">
          <v-card-title class="text-h5 mt-5 text-center">
            {{ comment.user_name }}
          </v-card-title>
          <v-card-text class="pb-0">
            {{ comment.user_comment }}
          </v-card-text>

          <v-img
            src="../assets/Images/av7 copy.png"
            height="100px"
            width="100"
            style="top: 30px"
            class="mx-auto"
          ></v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group> -->
    <!-- </div>  -->
    <v-btn color="#45b08c"
        v-scroll="onScroll"
        v-show="fab"
        fab
        light
        fixed
        bottom
        right
        @click="toTop"
    >
      <span class="material-icons-round" style="color: #ffffff">arrow_upward</span>
    </v-btn>
  </v-container>
</template>

<script>
import farsi from "@/store/Langs/farsi.json";

export default {
  props: ["lang", "DarkTheme"],
  name: "HelloWorld",

  data: () => ({
    tab: null,
    cast: false,
    call_log: false,
    chat: false,
    notif: false,
    send: true,
    model: null,
    rating: 4,
    fab: false,
    downloadFa: false,
  }),

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    downloadFaMetod() {
      if (this.lang.header.selectLang.country == Iran) {
        this.downloadFa == false;
      } else {
        this.downloadFa == true;
      }
    },
    changeRTL() {
      this.$vuetify.rtl = true;
    },

    changeStyle(tab) {
      if (tab == "cast" && this.cast != true) {
        (this.cast = !this.cast),
            (this.call_log = false),
            (this.chat = false),
            (this.notif = false),
            (this.send = false);
      } else if (tab == "call_log" && this.call_log != true) {
        (this.call_log = !this.call_log),
            (this.cast = false),
            (this.chat = false),
            (this.notif = false),
            (this.send = false);
      } else if (tab == "send" && this.send != true) {
        (this.send = !this.send),
            (this.cast = false),
            (this.chat = false),
            (this.notif = false),
            (this.call_log = false);
      } else if (tab == "notif" && this.notif != true) {
        (this.notif = !this.notif),
            (this.cast = false),
            (this.chat = false),
            (this.send = false),
            (this.call_log = false);
      } else if (tab == "chat" && this.chat != true) {
        (this.chat = !this.chat),
            (this.cast = false),
            (this.send = false),
            (this.notif = false),
            (this.call_log = false);
      }
    },
  },

  //   computed: {
  //      lang(){return this.lang}
  // }
};
</script>

<style>
.remove_right_padding {
  padding-right: 0px;
}

.Gradient {
  /* For browsers that do not support gradients */
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 253, 254, 1) 30%, rgba(236, 252, 253, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 253, 254, 1) 30%, rgba(236, 252, 253, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 253, 254, 1) 30%, rgba(236, 252, 253, 1) 70%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

.transparent {
  background-color: white !important;
  border-color: transparent !important;
  border-style: none !important;
  box-shadow: none !important;
}

.margin_top {
  margin-top: 64px;
}

.v-card__title {
  display: block;
  padding: 0px 16px 25px 16px !important;
}

.v-slide-group__prev .v-slide-group__prev--disabled {
  display: none;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
.v-slide-group__prev {
  display: none;
  visibility: hidden;
}

.bazar_fr_bgImg {
  background-image: url(../assets/Images/badge-new.png);
  background-size: 108%;
  background-repeat: no-repeat;
  background-position: center center;
}

.myket_fr_bgImg {
  background-image: url(../assets/Images/myket.logo.png);
  background-size: 106%;
  background-repeat: no-repeat;
  background-position: center center;
}

.googleplay_fr_bgImg {
  background-image: url(../assets/Images/GooglePlay_Logo.png);
  background-size: 118%;
  background-repeat: no-repeat;
  background-position: center center;
}

.notix_icon_size {
  width: 20px;
}

@media screen and (min-width: 961px) {
  .margin_top {
    margin-top: 170px;
  }

  .margin_top_card {
    margin-top: 140px;
  }

  .margin_bottom_card {
    margin-bottom: 140px;
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    display: flex !important;
  }

  .v-responsive__content {
    max-width: 100px;
  }

  .v-tab {
    width: 90px;
    max-width: 360px;
  }
}

.v-tab {
  min-width: 10px;
  max-width: 360px;
  padding: 0px 7px;
}

.v-responsive__content {
  width: 10px;
}

.v-slide-group__next,
.v-slide-group__prev {
  display: none;
}

.box_shadow {
  box-shadow: 0 !important;
}

.download_logo {
  height: auto !important;
  width: 70px !important;
}

.center {
  display: block;
  margin: auto;
  vertical-align: middle;
}

.download_section_box_shadow {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
  0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.white-box-shadow {
  box-shadow: 2px 2px 2px 1px rgba(245, 244, 244, 0.2);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.v-application .elevation-1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.v-tab:before {
  background-color: transparent;
}

.v-tabs-slider {
  display: none;
}

.v-slide-group__content {
  transition: 1 cubic-bezier(0.25, 0.8, 0.5, 1);
}

.download_btn {
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  display: inline-block;
  flex: 0 0 auto;
  font-weight: 100;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 15px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
  0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

@media all and (max-width: 30em) {
  .download_btn {
    display: block;
  }
}
</style>
