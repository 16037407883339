import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CountryFlag from 'vue-country-flag'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'material-icons/iconfont/round.css';

Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    AOS.init()
  },
}).$mount('#app')
