<template>
  <Privacy :DarkTheme="this.DarkTheme" :lang="this.Lang" />
</template>

<script>
  import Privacy from '../components/Privacy.vue'

  export default {
    props: [
    'DarkTheme',
    'Lang'
    ],
    
    name: 'Home',

    components: {
      Privacy,
    },

     mounted() {
      console.log(this.lang)
    },
  }
</script>
