<template>
  <v-container fluid>
    <div class="mx-15 my-6">
      <h1>{{ this.lang.privacy_policy.privacy.s0.tt }}</h1>
      <br>
      <p>
        {{ this.lang.privacy_policy.privacy.s0.p1 }}
      </p>
      <p>
        {{ this.lang.privacy_policy.privacy.s0.p2 }}
      </p>
      <p>
        {{ this.lang.privacy_policy.privacy.s0.p3 }}
      </p>
      <p>
        {{ this.lang.privacy_policy.privacy.s0.p4 }}
      </p>

      <p><h3>
        {{ this.lang.privacy_policy.privacy.s1.tt }}</h3></p>
      <p><h4>
        {{ this.lang.privacy_policy.privacy.s1.s0.tt }}</h4></p>
      {{ this.lang.privacy_policy.privacy.s1.s0.p1 }}
      <p><h4>
        {{ this.lang.privacy_policy.privacy.s1.s1.tt }}</h4></p>
      {{ this.lang.privacy_policy.privacy.s1.s1.p1 }}
      <p><h4>
        {{ this.lang.privacy_policy.privacy.s2.tt }}</h4></p>
      <div><p>
        {{ this.lang.privacy_policy.privacy.s2.p1 }}
      </p>
        <p>
          {{ this.lang.privacy_policy.privacy.s2.p2 }}
        </p>
        <ul>
          <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play
            Services</a></li>
          <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a>
          </li>
          <li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google
            Analytics for Firebase</a></li>
          <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase
            Crashlytics</a></li>
        </ul>
      </div>

      <p><h3>
        {{ this.lang.privacy_policy.privacy.s3.tt }}</h3></p>
      <p><h4>
        {{ this.lang.privacy_policy.privacy.s3.s0.tt }}</h4></p>
      {{ this.lang.privacy_policy.privacy.s3.s0.p1 }}

      <p><h4>
        {{ this.lang.privacy_policy.privacy.s3.s1.tt }}</h4></p>
      {{ this.lang.privacy_policy.privacy.s3.s1.p1 }}

      <p><h3>{{ this.lang.privacy_policy.privacy.s4.tt }}</h3></p>
      {{ this.lang.privacy_policy.privacy.s4.p1 }}
      <p>{{ this.lang.privacy_policy.privacy.s4.p2 }}</p>

      <ul>
        <li><p>{{ this.lang.privacy_policy.privacy.s4.p3 }}</p></li>
        <li><p>{{ this.lang.privacy_policy.privacy.s4.p4 }}</p></li>
        <li><p>{{ this.lang.privacy_policy.privacy.s4.p5 }}</p></li>
        <li><p>{{ this.lang.privacy_policy.privacy.s4.p6 }}</p></li>
        <li><p>{{ this.lang.privacy_policy.privacy.s4.p7 }}</p></li>
      </ul>

      <p><h3>{{ this.lang.privacy_policy.privacy.s5.tt }}</h3></p>
      <p>
        {{ this.lang.privacy_policy.privacy.s5.p1 }}
      </p>
      <ul>
        <li>{{ this.lang.privacy_policy.privacy.s5.p2 }}</li>
        <li>{{ this.lang.privacy_policy.privacy.s5.p3 }}</li>
        <li>{{ this.lang.privacy_policy.privacy.s5.p4 }}</li>
        <li>{{ this.lang.privacy_policy.privacy.s5.p5 }}</li>
      </ul>

      <p><h3>{{ this.lang.privacy_policy.privacy.s6.tt }}</h3></p>
      <p>
        {{ this.lang.privacy_policy.privacy.s6.p1 }}
      </p>
      <p><h3>{{ this.lang.privacy_policy.privacy.s7.tt }}</h3></p>
      <p>
        {{ this.lang.privacy_policy.privacy.s7.p1 }}
      </p>
      <p><h3>{{ this.lang.privacy_policy.privacy.s8.tt }}</h3></p>
      <p>
        {{ this.lang.privacy_policy.privacy.s8.p1 }}
      </p>
      <p>{{ this.lang.privacy_policy.privacy.s8.p2 }}</p>
      <p><h3>{{ this.lang.privacy_policy.privacy.s9.tt }}</h3></p>
      <p>
        {{ this.lang.privacy_policy.privacy.s9.p1 }}
      </p>

      <h1>Terms &amp; Conditions</h1>
      <p>
        By downloading or using the app, these terms will
        automatically apply to you – you should make sure therefore
        that you read them carefully before using the app.You’re not
        allowed to copy or modify the app, any part of the app, or
        our trademarks in any way.You’re not allowed to attempt to
        extract the source code of the app, and you also shouldn’t try
        to translate the app into other languages or make derivative
        versions.The app itself, and all the trademarks, copyright,
        database rights, and other intellectual property rights related
        to it, still belong to Arteh.
      </p>
      <p>
        Arteh is committed to ensuring that the app is
        as useful and efficient as possible.For that reason, we
        reserve the right to make changes to the app or to charge for
        its services, at any time and for any reason.We will never
        charge you for the app or its services without making it very
        clear to you exactly what you’re paying for.
      </p>
      <p>
        The Cheetah app stores and processes Non-Personal data that
        you have provided to us, to provide our
        Service.It’s your responsibility to keep your phone and
        access to the app secure.We therefore recommend that you do
        not jailbreak or root your phone, which is the process of
        removing software restrictions and limitations imposed by the
        official operating system of your device.It could make your
        phone vulnerable to malware/viruses/malicious programs,
        compromise your phone’s security features and it could mean
        that the Cheetah app won’t work properly or at all.
      </p>
      <div><p>
        The app does use third-party services that declare their
        Terms and Conditions.
      </p>
        <p>
          Link to Terms and Conditions of third-party service
          providers used by the app
        </p>
        <ul>
          <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play
            Services</a></li>
          <li><a href="https://developers.google.com/admob/terms" target="_blank"
                 rel="noopener noreferrer">AdMob</a></li>
          <li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google
            Analytics
            for Firebase</a></li>
          <li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase
            Crashlytics</a></li>
        </ul>
      </div>
      <p>
        With respect to Arteh’s responsibility for your
        use of the app, when you’re using the app, it’s important to
        bear in mind that although we endeavor to ensure that it is
        updated and correct at all times, we do rely on third parties
        to provide information to us so that we can make it available
        to you.Arteh accepts no liability for any
        loss, direct or indirect, you experience as a result of
        relying wholly on this functionality of the app.
      </p>
      <p>
        At some point, we may wish to update the app.The app is
        currently available on Android – the requirements for the
        system(and for any additional systems we
        decide to extend the availability of the app to) may change,
        and you’ll need to download the updates if you want to keep
        using the app.Arteh does not promise that it
        will always update the app so that it is relevant to you
        and/or works with the Android version that you have
        installed on your device.
      </p>
      <p><h3>Changes to This Terms and Conditions</h3></p>
      <p>
        We may update our Terms and Conditions
        from time to time.Thus, you are advised to review this page
        periodically for any changes.We will
        notify you of any changes by posting the new Terms and
        Conditions on this page.
      </p>
      <p>
        These terms and conditions are effective as of 2021-10-28
      </p>
      <p><h3>Contact Us</h3></p>
      <p>
        If you have any questions or suggestions about our
        Terms and Conditions, do not hesitate to contact us
        at support@arteh.app.
      </p>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "Privacy",
  props: ["lang", "DarkTheme"],
  mounted() {
    window.scrollTo(0, 0);
    console.log(this.lang)
  },
  data: () => ({
    lang: english,
  }),
};
</script>

<style>
</style>