<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        :color="this.DarkTheme == true ? '#212121' : 'none'"
        app
        temporary
        :left="selectLang.country == 'Iran' || selectLang.country == 'arabic'"
        :right="selectLang.country != 'Iran' && selectLang.country != 'arabic'"
    >
      <v-btn
          class="transparent my-2"
          block
          @click="
          goHome();
          $vuetify.goTo('#section1');
          drawer = !drawer;
        "
      >
        {{ lang.header.home }}
      </v-btn>

      <v-btn
          v-if="this.$route.path != '/privacy'"
          class="transparent my-2"
          block
          @click="
          $vuetify.goTo('#notix');
          drawer = !drawer;
        "
      >
        {{ lang.header.notix }}
      </v-btn>

      <v-btn
          v-if="this.$route.path != '/privacy'"
          class="transparent my-2"
          block
          @click="
          $vuetify.goTo('#section3');
          drawer = !drawer;
        "
      >
        {{ lang.header.features }}
      </v-btn>

      <v-btn
          v-if="this.$route.path != '/privacy'"
          class="transparent my-2"
          block
          @click="
          $vuetify.goTo('#section4');
          drawer = !drawer;
        "
      >
        {{ lang.header.download }}
      </v-btn>

      <v-btn
          v-if="this.$route.path != '/privacy'"
          class="transparent my-2"
          block
          @click="
          $vuetify.goTo('#section5');
          drawer = !drawer;
        "
      >
        {{ lang.header.user_review }}
      </v-btn>

      <v-btn
          class="transparent my-2"
          block
          @click="
          gotoPrivacy();
          drawer = !drawer;
        "
      >{{ lang.header.privacy }}
      </v-btn
      >

      <v-divider></v-divider>
      <v-list-group class="text-center mt-5" no-action>
        <v-list-item slot="activator" class="px-0">
          <country-flag
              :country="selectLang.flag"
              size="normal"
              style="width: 76px"
          />
          <v-list-item-title>{{ selectLang.country_title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, i) in Langitems"
            :key="i"
            @click="changeLang(item.country, item.flag)"
            class="text-center ml-6"
        >
          <country-flag
              class="dropdown_flag"
              :country="item.flag"
              size="normal"
              style="width: 76px"
          />
          <v-list-item-title>{{ item.country_title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-navigation-drawer>

    <v-app-bar
        :class="{ transparent_header: !this.DarkTheme }"
        color="#212121"
        elevate-on-scroll
        app
    >
      <img class="mx-3" :src="require('@/assets/Logo/logo.png')" height="40"/>
      <v-toolbar-title class="title">{{ lang.header.title }}</v-toolbar-title>

      <v-btn
          icon
          id="mode-switcher"
          @click="
          $vuetify.theme.dark = !$vuetify.theme.dark;
          changedark();
        "
      >
        <v-icon class="material-icons-round" :color="$vuetify.theme.dark ? 'white' : 'orange'">
          {{ $vuetify.theme.dark ? "dark_mode" : "light_mode" }}
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-icon
          @click.stop="drawer = !drawer"
          class="material-icons-round nav" :color="$vuetify.theme.dark ? 'white' : 'black'">
        {{"menu"}}
      </v-icon>

      <v-toolbar-items class="hidden-sm-and-down font-weight-bold">
        <v-btn
            class="transparent"
            flat
            @click="
            goHome();
            $vuetify.goTo('#section1');
          "
        >
          {{ lang.header.home }}
        </v-btn>

        <v-btn
            v-if="this.$route.path != '/privacy'"
            class="transparent"
            flat
            @click="$vuetify.goTo('#notix')"
        >
          {{ lang.header.notix }}
        </v-btn>

        <v-btn
            v-if="this.$route.path != '/privacy'"
            class="transparent"
            flat
            @click="$vuetify.goTo('#section3')"
        >
          {{ lang.header.features }}
        </v-btn>

        <v-btn
            v-if="this.$route.path != '/privacy'"
            class="transparent"
            flat
            @click="$vuetify.goTo('#section4')"
        >
          {{ lang.header.download }}
        </v-btn>

        <v-btn
            v-if="this.$route.path != '/privacy'"
            class="transparent"
            flat
            @click="$vuetify.goTo('#section5')"
        >
          {{ lang.header.user_review }}
        </v-btn>

        <v-btn class="transparent" flat @click="gotoPrivacy">
          {{ lang.header.privacy }}
        </v-btn>

        <v-divider vertical inset></v-divider>

        <v-menu transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="transparent" v-bind="attrs" v-on="on">
              {{ selectLang.country_title }}
              <country-flag :country="selectLang.flag" size="normal"/>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                link
                v-for="(item, i) in Langitems"
                :key="i"
                @click="changeLang(item.country, item.flag)"
            >
              <v-list-item-title>{{ item.country_title }}</v-list-item-title>
              <country-flag
                  class="dropdown_flag"
                  :country="item.flag"
                  size="normal"
                  style="width: 82px"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view :DarkTheme="this.DarkTheme" :Lang="this.lang"/>
    </v-main>

    <v-footer dark padless>
      <v-card flat tile color="#212121" class="white--text text-center">
        <div class="white--text py-5 mx-8 row d-flex flex-row justify-center">
          <div class="col-12 col-sm-6 col-md-4 transparent px-0">
            <v-list color="transparent text-md-start" disabled>
              <v-subheader>{{ lang.footer.contact_to_us.title }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item-group>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-email-outline'"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                        v-text="'Email : support@arteh.app'"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

              </v-list-item-group>
            </v-list>
          </div>

          <div class="col-12 col-sm-6 col-md-4">
            <div class="">
              <v-list color="transparent subheader_text_align">
                <v-subheader class="subheader_text_align">{{
                    lang.footer.menu.title
                  }}
                </v-subheader>
                <v-divider></v-divider>
                <v-list-item-group>
                  <v-list-item dense class="margin-footer-btn">
                    <v-list-item-content
                        @click="
                        goHome();
                        $vuetify.goTo('#section1');
                      "
                    >
                      <v-list-item-title
                          v-text="this.lang.footer.menu.home"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                      dense
                      class="margin-footer-btn"
                      v-if="this.$route.path != '/privacy'"
                  >
                    <v-list-item-content @click="$vuetify.goTo('#notix')">
                      <v-list-item-title
                          v-text="this.lang.footer.menu.notix"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                      dense
                      class="margin-footer-btn"
                      v-if="this.$route.path != '/privacy'"
                  >
                    <v-list-item-content @click="$vuetify.goTo('#section3')">
                      <v-list-item-title
                          v-text="this.lang.footer.menu.features"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                      dense
                      class="margin-footer-btn"
                      v-if="this.$route.path != '/privacy'"
                  >
                    <v-list-item-content @click="$vuetify.goTo('#section4')">
                      <v-list-item-title
                          v-text="this.lang.footer.menu.download"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                      dense
                      class="margin-footer-btn"
                      v-if="this.$route.path != '/privacy'"
                  >
                    <v-list-item-content @click="$vuetify.goTo('#section5')">
                      <v-list-item-title
                          v-text="this.lang.footer.menu.user_review"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </div>

          <div class="col-12 col-md-4">
            <div>
              <img
                  class="mr-3 text-center"
                  :src="require('@/assets/Logo/logo.png')"
                  height="50"
              />
              <v-toolbar-title class="title text-center">{{
                  this.lang.footer.cheetah.title
                }}
              </v-toolbar-title>
            </div>
            <p class="mt-5 text-center text-md">
              {{ this.lang.footer.cheetah.subtitle }}
            </p>

            <v-btn
                v-for="icon in icons"
                :key="icon"
                class="mx-4 white--text"
                icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Cheetah</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import CountryFlag from "vue-country-flag";
import english from "../src/store/Langs/eng.json";
import farsi from "../src/store/Langs/farsi.json";
import arabic from "../src/store/Langs/arabic.json";
import chinese from "../src/store/Langs/chinese.json";
import France from "../src/store/Langs/france.json";
import germany from "../src/store/Langs/germany.json";
import India from "../src/store/Langs/hindi.json";
import Japan from "../src/store/Langs/japanese.json";
import Russia from "../src/store/Langs/russian.json";
import Spain from "../src/store/Langs/spanish.json";

export default {
  name: "App",
  components: {
    CountryFlag,
  },

  data: () => ({
    lang: english,

    items: [
      {title: "Home", icon: "mdi-home-city"},
      {title: "My Account", icon: "mdi-account"},
      {title: "Users", icon: "mdi-account-group-outline"},
    ],

    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],

    navLinks: [
      {
        text: "Drivers",
        subLinks: [
          {text: "Add Driver", route: "/drivers/add"},
          {text: "Add Driver", route: "/drivers/add"},
        ],
      },
    ],

    selectedItem: 1,
    cheetah_items: [
      {text: "email :"},
      {text: "Audience"},
      {text: "Conversions"},
    ],

    DarkTheme: true,
    drawer: false,
    group: null,
    elevateOnScroll: false,
  }),

  methods: {
    gotoPrivacy() {
      this.$router.push("/privacy");
    },

    goHome() {
      this.$router.push("/");
    },

    menuItems() {
      return this.menu;
    },

    changedark() {
      this.DarkTheme = !this.DarkTheme;
    },

    changeLang(country, flag) {
      if (country == "Iran") {
        this.changeRTL();
        this.lang = farsi;
      } else this.changeLTR();

      if (country == "English") this.lang = english;
      else if (country == "China") this.lang = chinese;
      else if (country == "France") this.lang = France;
      else if (country == "Germany") this.lang = germany;
      else if (country == "India") this.lang = India;
      else if (country == "Japan") this.lang = Japan;
      else if (country == "Russia") this.lang = Russia;
      else if (country == "Spain") this.lang = Spain;
      else if (country == "arabic") {
        this.changeRTL();
        this.lang = arabic;
      }

      this.selectLang.country = country;
      this.selectLang.flag = flag;
    },

    changeRTL() {
      this.$vuetify.rtl = true;
    },

    changeLTR() {
      this.$vuetify.rtl = false;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },

  mounted() {
    window.scrollTo(0, 0);

    if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.DarkTheme = true;
      this.$vuetify.theme.dark = true;
    } else {
      this.DarkTheme = false;
      this.$vuetify.theme.dark = false;
    }
  },

  computed: {
    selectLang() {
      return this.lang.header.selectLang;
    },
    Langitems() {
      return this.lang.header.Langitems;
    },
  },
};
</script>

<style>
.transparent {
  background-color: white !important;
  border-color: transparent !important;
  border-style: none !important;
  box-shadow: none !important;
}

.transparent_header {
  background-color: white !important;
  border-color: transparent !important;
  border-style: none !important;
}

@font-face {
  font-family: Roboto-Medium;
  src: url("../src/assets/Fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Planet;
  src: url("../src/assets/Fonts/planet.otf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("../src/assets/Fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Vazir-Bold;
  src: url("../src/assets/Fonts/Vazir-Bold.ttf");
}

.v-application {
  font-family: Roboto-Medium, Vazir-Bold;
}

.v-application .title {
  font-family: Planet, Vazir-Bold !important;
}

.v-application .mx-3 {
  margin-right: 12px !important;
  margin-left: 100px !important;
}

.normal-flag {
  margin: 0 !important;
}

.dropdown_flag {
  width: 80px;
}

.margin-footer-btn {
  margin-left: 20px;
  margin-right: 20px;
}

.v-subheader {
  align-items: center;
  display: block;
  height: 35px;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0 16px 0 16px;
}



@media screen and (min-width: 961px) {
  .margin-footer-btn {
    margin-left: 100px;
    margin-right: 100px;
  }

  .v-application .nav{
    display: none;
  }

  .v-footer {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .v-application .mx-3 {
    margin-right: 12px !important;
    margin-left: 20px !important;
  }
}

.theme--dark.v-application {
  background: #212121;
  color: #ffffff;
}

row {
  margin: 0px;
}
</style>
