<template>
  <hello-world :DarkTheme="this.DarkTheme" :lang="this.Lang" />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    props: [
    'DarkTheme',
    'Lang'
    ],
    
    name: 'Home',

    components: {
      HelloWorld,
    },

     mounted() {
      console.log(this.DarkTheme)
    },
  }
</script>
