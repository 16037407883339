import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import privacy from '../views/PrivacyView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Cheetah',
    component: HomeView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});